// React
import React, { useEffect, useState } from 'react';

// Design
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  TextField,
  Button,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Internal
import API from '../../utils/api';
import createAccess from '../../utils/functions/createAccess';

// Third-party

function NotificationDialog({ open, setOpen, selectedChats = [], user }) {
  const [message, setMessage] = useState('');
  const [selected, setSelected] = useState([]);
  const [blockSend, setBlockSend] = useState(false);

  const removeFromList = (id) => {
    setSelected((prev) => prev.filter((chat) => chat._id !== id));
  };

  const resetNumbers = () => {
    setSelected(selectedChats);
  };

  const handleSendMessage = () => {
    setBlockSend(true);
    // send message to selectedChats
    let access_click2chat = createAccess(user);
    let numbers = selected.map((chat) => chat.phone_number);
    let obj = {
      access_click2chat,
      numbers,
      message,
    };
    API.put
      .notifyUsers({ ...obj })
      .then(() => {})
      .catch((err) => console.log(err))
      .finally(() => {
        setBlockSend(false);
        setOpen(false);
      });
  };

  useEffect(() => {
    if (open) {
      setSelected(selectedChats);
    } else {
      setSelected([]);
      setBlockSend(false);
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Enviar mensagem para os chats selecionados</DialogTitle>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          margin: '1rem',
        }}
      >
        <Box>
          <List>
            {selected.map((chat) => (
              <ListItemButton key={chat._id}>
                <ListItemIcon>
                  <CloseIcon
                    color="error"
                    onClick={() => removeFromList(chat._id)}
                  />
                </ListItemIcon>
                <ListItemText primary={`${chat.phone_number}`} />
              </ListItemButton>
            ))}
          </List>
        </Box>
        <TextField
          label="Messagem"
          multiline
          rows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          disabled={
            !Array.isArray(selected) ||
            (Array.isArray(selected) && selected.length === 0)
          }
        />
      </Box>
      <DialogActions>
        {!(selected?.length === selectedChats?.length) && (
          <Button
            onClick={resetNumbers}
            disabled={selected?.length === selectedChats?.length}
          >
            Adicionar todos os números
          </Button>
        )}

        <Button onClick={() => setOpen(false)} color="error">
          Cancelar
        </Button>
        <Button
          onClick={handleSendMessage}
          disabled={
            !Array.isArray(selected) ||
            (Array.isArray(selected) && selected.length === 0) ||
            !message ||
            blockSend
          }
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NotificationDialog;
