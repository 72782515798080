// React
import React, { useState, useEffect } from 'react';

// Design
import { makeStyles } from '@mui/styles';
import {
  Container,
  Box,
  Button,
  TextField,
  Link,
  Typography,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

// Internal
import NotificationBar from '../../components/Feedback/notification';
import API from '../../utils/api';

// import { setNotificationBarOpen } from '../../store/app/slice';
import { signIn, setCompanyURL } from '../../store/app/slice';
import '../../assets/css/signin.css';
import VERSION from '../../version';

// Third-party
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// Styles
import styles from '../../assets/jss/Pages/signInStyle.js';
const useStyles = makeStyles(styles);

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'GilsonNeto © '}
      <Link color="inherit" href="https://clickservices.com.br/">
        Click2Chat - Interface -
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

function Appversion() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      Versão {VERSION}
    </Typography>
  );
}

export default function SignIn() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const params = useParams();

  const [logo, setLogo] = useState('');
  const [user, setUser] = useState(null);
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [blockLogin, setBlockLogin] = useState(false);

  const handleShowPassword = (e) => {
    setShowPassword(!showPassword);
  };

  const handleImage = async () => {
    let response = await API.get.companyLogo(params['company_name']);
    try {
      if (response?.data?.b64) {
        let b64 = response.data.b64;
        return b64;
      } else {
        return 'no image';
      }
    } finally {
    }
  };

  const handleLogin = (e) => {
    setBlockLogin(true);
    e.preventDefault();
    let pass = {
      u: user,
      p: password,
    };
    dispatch(signIn(pass));
    setTimeout(() => {
      setBlockLogin(false);
    }, 5000);
  };

  useEffect(() => {
    setBlockLogin(false);
    if (document?.title) {
      document.title = `Click2Chat`;
    }
    dispatch(setCompanyURL(params['company_name']));
    handleImage().then((_img) => {
      if (_img && _img === 'no image') {
        setLogo('');
      } else if (_img) {
        setLogo(_img);
      }
    });
    // console.log({_img})
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <NotificationBar />
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Box
            display="flex"
            sx={{
              heigth: '20vh',
              width: '20vw',
              justifyContent: 'center',
              alignItems: 'center',
              // marginTop: '15vh'
            }}
          >
            {logo ? (
              <img
                src={`data:image/png;base64, ${logo}` || ''}
                alt={'logo'}
                width={'80%'}
                height={'80%'}
                style={{
                  objectFit: 'cover',
                }}
              />
            ) : (
              <img src="/images/Logo.png" className="App-tipo" alt="logo" />
            )}
          </Box>
          <form className={classes.form} noValidate onSubmit={handleLogin}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="user"
              label="Usuário"
              name="user"
              onChange={(event) => setUser(event.currentTarget.value)}
              onLoad={(event) => setUser(event.currentTarget.value)}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type={showPassword ? 'text' : 'password'}
              id="password"
              onChange={(event) => setPassword(event.currentTarget.value)}
              autoComplete="current-password"
              onLoad={(event) => setUser(event.currentTarget.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      onMouseDown={handleShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={blockLogin}
            >
              Entrar
            </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
        <Box>
          <Appversion />
        </Box>
      </Container>
    </React.Fragment>
  );
}
